import React, { ReactNode, useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Container, Spinner } from "react-bootstrap";
import styles from "./style.module.scss";
import clsx from "clsx";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useNavigate, useParams } from "react-router-dom";
import { Paths } from "utils/router";

interface SelectVersionProps {
  selectedItem?: any;
  apiDocumentSchema?: any;
  onSelect: (item: any) => void;
}

export const QueryList = (props: SelectVersionProps): React.JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();

  const { selectedItem, onSelect, apiDocumentSchema } = props;
  const selectedItemDetail = selectedItem?.detail;

  const [queryCommands, setQueryCommands] = useState<any>([]);
  const [mutationCommands, setMutationCommands] = useState<any>([]);

  const staticPages = [
    {
      type: "static",
      name: "Home",
      text: (
        <>
          <div className="infinipi-doc">
            <br />
            <h1>Welcome to the Infinipi API Access Documentation!</h1>
            <p>
              Here, merchants will discover seamless integration possibilities
              for their payment sites with our cutting-edge payment gateway.
              This comprehensive guide empowers merchants to effortlessly
              integrate their systems with Infinipi's API, enabling streamlined
              access to transactional data, particularly focusing on pending
              transactions.
            </p>

            <h2>1. Getting Started</h2>
            <p>
              Step-by-step instructions on how to obtain API credentials and
              authenticate access to the Infinipi platform.
            </p>

            <h2>2. Integration Guidelines</h2>
            <p>
              Detailed guidance on integrating the Infinipi API seamlessly into
              merchant payment sites, ensuring smooth communication and data
              exchange.
            </p>

            <h2>3. Transaction Retrieval</h2>
            <p>
              Specific endpoints and methods to retrieve pending transaction
              data efficiently.
            </p>

            <h2>4. Error Handling and Troubleshooting</h2>
            <p>
              Strategies for addressing common issues and handling errors during
              integration and data retrieval processes.
            </p>

            <p>
              By integrating with Infinipi's API, merchants can optimize their
              payment workflows, gain valuable insights into pending
              transactions, and offer customers a seamless payment experience.
            </p>

            <p>
              Empower your payment site with Infinipi's powerful API today and
              elevate your transaction management to new heights!
            </p>
            <p>
              To access the Infinipi API features, please log in using the login
              button on top.
            </p>
          </div>
        </>
      ),
    },
    {
      type: "static",
      name: "Add API key",
      text: (
        <>
          <div className="infinipi-doc">
            <br />
            <h1>Add API key</h1>
            <p>
              <h4>You can generate keypair from CLI</h4>
              <i>
                First line generates private key, second generates public key
                from private key
              </i>
              <SyntaxHighlighter language="bash">
                {`openssl ecparam -genkey -name prime256v1 -noout -out private_key.pem
openssl ec -in private_key.pem -pubout -out public_key.pem
`}
              </SyntaxHighlighter>
            </p>
            <br />
            <p>
              <h4>When you have your EC256 key already generated</h4>
              <ol>
                <li>
                  Login to{" "}
                  <a href="https://infinipi.io/">https://infinipi.io/</a>
                </li>
                <li>
                  Choose <strong>"API Keys"</strong> on left side menu
                </li>
                <li>
                  Choose <strong>"Add API key"</strong> on top right side of
                  screen
                </li>
                <li>Paste your public key to the textarea</li>
                <li>
                  Click <strong>"Submit public key"</strong>
                </li>
                <li>
                  Follow the instructions in you{" "}
                  <a href="https://autheid.com/">AuthEID</a> application on
                  mobile
                </li>
              </ol>
            </p>
            <p>
              <h4>When you don't have your EC256 key and you need a new one</h4>
              <ol>
                <li>
                  Login to{" "}
                  <a href="https://infinipi.io/">https://infinipi.io/</a>
                </li>
                <li>
                  Choose <strong>"API Keys"</strong> on left side menu
                </li>
                <li>
                  Choose <strong>"Add API key"</strong> on top right side of
                  screen
                </li>
                <li>
                  Click on <strong>"Generate new API key"</strong>
                </li>
                <li>
                  New textarea <strong>"Private key"</strong> will appear.
                </li>
                <li>
                  Copy your new private key in safe place, for later usage with
                  api
                </li>
                <li>
                  Click <strong>"Submit public key"</strong>
                </li>
                <li>
                  Follow the instructions in you{" "}
                  <a href="https://autheid.com/">AuthEID</a> application on
                  mobile
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
    },
    {
      type: "static",
      name: "Authenticate to api and create PI",
      text: (
        <>
          <div className="infinipi-doc">
            <br />
            <h1>Authenticate to api</h1>
            <p>
              To make a request to API, you need to login first to our login
              server.
              <br />
              You can do this using websocket request to{" "}
              <strong>wss://merchant-login.infinipi.io/ws/v1/websocket</strong>
              . <br />
            </p>
            <p>
              <i>example payload:</i>
              <SyntaxHighlighter language="json" wrapLines wrapLongLines>
                {`{
  "api": "login",
  "method": "new",
  "message_id": "20aa673dcb174ff5a0c1cb7df9dde4c8",
    "service_url": "wss://merchant.infinipi.com",
  "args": {
      "signed_challange": "{\\"header\\":{\\"cde\\":\\"{\\\\\\"thumbprint\\\\\\": \\\\\\"HIafDcQMJhuflJw5tAFn_r09Gts2bzXX8I3-Y2HwjSE\\\\\\", \\\\\\"created\\\\\\": \\\\\\"2021-12-10 10:06:15.701598\\\\\\", \\\\\\"service_url\\\\\\": \\\\\\"wss://api-dev.infinipi.io\\\\\\"}\\",\\"kid\\":\\"HIafDcQMJhuflJw5tAFn_r09Gts2bzXX8I3-Y2HwjSE\\",\\"phn\\":\\"+13235534022\\"},\\"payload\\":\\"eyJ0aHVtYnByaW50IjogIkhJYWZEY1FNSmh1ZmxKdzV0QUZuX3IwOUd0czJielhYOEkzLVkySHdqU0UiLCAiY3JlYXRlZCI6ICIyMDIxLTEyLTEwIDEwOjA2OjE1LjcwMTU5OCIsICJzZXJ2aWNlX3VybCI6ICJ3c3M6Ly9hcGktZGV2LmJsb2Nrc2V0dGxlLmNvbSJ9\\",\\"protected\\":\\"eyJhbGciOiJFUzI1NiJ9\\",\\"signature\\":\\"TTsD4q1CDBZk1j41yI-S5GTzgVJm0CRiBwUF3S4Pl-Z5oEzsD9rdFJIRUaK0usGkE0Go2rC-05tZwKm9fiKwZQ\\"}"
  }
}`}
              </SyntaxHighlighter>
            </p>
            <br />
            <p>
              <i>example response:</i>

              <SyntaxHighlighter language="json" wrapLines wrapLongLines>
                {`
{
  "error": null,
  "data": {
      "access_token": "eyJhbGciOiJFQ0RILUVTIiwiZW5jIjoiQTI1NkNCQy1IUzUxMiIsImVwayI6eyJjcnYiOiJQLTI1NiIsImt0eSI6IkVDIiwieCI6Iko2LTBXQ0dMUmhJTUZjdlNxamthd25kUkEtQ0N4akR3NjNBa2FETlpuLU0iLCJ5IjoiZWNVdW14ZXF0YkJfZEIzREozLVJzN1NxVmJVU2ZBcm5KRGQtSjlFNHR5USJ9LCJraWQiOiJwZDFhU0dUd0VhUzV6c0cydWkxenF5eXAzZEY4bVUyTHFiMlo2cjgya04wIiwidHlwIjoiSldFIn0..Kij3e4FC3OZsmfo8tAHlFg.IjoCjt08Y7IjW7fAAWJqb9Kfknqlh-WVWzmtjlliigb9jOa3VAw6QPFGGDXHZkcuZrumju2ZPxoNgTChp7ei-ZtHDCEBWMt5vJr4YFGCCOVz6xcb1fOXwwN-D2klk7SXNXsPgEMsZX56XFsnucHllQJIjzU9Xazjk8bDyvuR5dsLAiLI7K-V7GRGgxyo6l2gNjnfU3zIhS7ReU_-9UU8S9mZQMZN_wkdnS4-L5YReMdUePatULNitwmMr313NZqtddA0NB6vz0qYBiVPczkr9WWERvfshGAkOHp1KOXYp8ueJUe8CvXB_tn51f2k17XXOcSJHq5oIm9reEPLHd3TZVVhJOBAIoJtm8QxBUZkMDppuDSw5ymM2cPiADexhVBAEAABTqyF4AGnT4Wl5vZA9zeqSw_AUFg64sX-mCZsY4VQpvHDhY10EN8zD1kG_PFb_zci293YjxnQt5KLaqTeQA.6H4OM27hVZldYFcNDtCPBErJJDJb9D3ouPLoyQjWF28",
      "grant": "basic",
      "expires_in": 600
  },
  "api": "login",
  "method": "new",
  "message_id": "b88e2550fc9d44c4a86a0014812625ac"
}`}
              </SyntaxHighlighter>
            </p>
            <br />
            <p>
              <i>
                In order to generate a signed request you can use jwcrypto
                package like in our example python code:
              </i>
              <SyntaxHighlighter language="python" wrapLines wrapLongLines>
                {`
from websockets.sync.client import connect
from jwcrypto.common import json_encode
from jwcrypto import jwk, jws
import json
import datetime
import random
import string
        
def get_token(host=None, private_key=None):
    try:
        key = jwk.JWK()
        key.import_from_pem(private_key)
        data = {
            "thumbprint": key.thumbprint(),
            "created": "{}".format(datetime.datetime.utcnow()),
            "service_url": "wss://merchant.infinipi.io"
        }
        token = json.dumps(data)
        jws_token = jws.JWS(token.encode('utf-8'))
        header = {
            "kid": key.thumbprint(),    
        } 
        jws_token.add_signature(key, None, json_encode({'alg': "ES256"}), json_encode(header))
        serialized_token = jws_token.serialize(compact=False)
        requestdata = {
            "method": "new",
            "api": "login",
            "args": {
                "signed_challenge": serialized_token
            },
            "service_url": "wss://merchant.infinipi.io",
            "message_id": ''.join(random.choice(string.ascii_lowercase) for i in range(32)),
        }
        with connect(host) as websocket:
            payload = json.dumps(requestdata)
            websocket.send(payload)
            message = websocket.recv()
            message_json = json.loads(message)
            return message_json
    except Exception as e:
        print(e)
        return None`}
              </SyntaxHighlighter>
            </p>
            <br />
            Generated token should be sent to our api server{" "}
            <strong>wss://merchant.infinipi.io/ws/gql</strong>
            <br />
            <br />
            <p>
              <i>example payload:</i>
              <SyntaxHighlighter language="json" wrapLines wrapLongLines>
                {`{
  message_type: "auth",
  access_token: token,
}`}
              </SyntaxHighlighter>
            </p>
            <p></p>
          </div>
          next step before sending initiatePi is to create a Pi record
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "message_id": "123e4567-e89b-12d3-a456-426614174000",
  "message_type": "query",
  "query": "mutation { createPi(amount: \\"100.00\\", currencyName: \\"USD\\", clientId: \\"12345\\") { payment { txRef } challenge } }",
  "access_token": "your_access_token"
}`}
          </SyntaxHighlighter>
          <p>
            you'll receive txref and challenge that should be used queries
            described below
          </p>
          <h3>General query structure</h3>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "message_type": "gateway",
  "message_id": "<UUID string>",
  "signed_challenge": "<signed challenge token>",
  "query": "<query string>",
  "variables": "<query variables>"
}`}
          </SyntaxHighlighter>
        </>
      ),
    },
    {
      type: "static",
      name: "API token renewal",
      text: (
        <>
          <div className="infinipi-doc">
            <br />
            <h1>API token renewal</h1>
            You need to renew your token after time that is mentioned in server
            response when you receive login token:
            <br />
            You can do this using websocket request to{" "}
            <strong>wss://merchant-login.infinipi.io/ws/v1/websocket</strong>
            . <br />
            <br />
            <p>
              <i>example payload:</i>
              <SyntaxHighlighter language="json" wrapLines wrapLongLines>
                {`{
  "api": "login",
  "method": "renew",
  "message_id": "20aa673dcb174ff5a0c1cb7df9dde4c8",
  "args": {
    "access_token": "eyJhbGciOiJFQ0RILUVTIiwiZW5jIjoiQTI1NkNCQy1IUzUxMiIsImVwayI6eyJjcnYiOiJQLTI1NiIsImt0eSI6IkVDIiwieCI6Ijg2b3ZsamFKOTdCWHo1RnZrcHk3RVJtT1RDZFZvMzlnZXQ4OHNBdWNvbGMiLCJ5Ijoiem9ZdW1hVDNwSW1GZ0dUcWdTVThLeERXMGo1S1lqWmNWZmQwNDVaLVNwayJ9LCJraWQiOiJwZDFhU0dUd0VhUzV6c0cydWkxenF5eXAzZEY4bVUyTHFiMlo2cjgya04wIiwidHlwIjoiSldFIn0..t-fXTCAfBzy4I5nXb0Gv_w.8werxbdDvmV8bCFL-E_yJbgID0tyyEQk7XByF0pwCPwlEpRiPgPP08S6yJfhFGWMc5B60MhYuSrAxfELXXKu-c-PcBdkvabTMOidVOyCaGhAQJC07uNwhP06rudPtXfBdGdGW65gGgKzEyqOw2tEWQQpMZkcUIJNkNRxxw2rphmnC9ckY6SOEurx9g8OualdzKaPe8jHMklsDhI3f_xxMr8J6ODifDnBvr0UyYnjuewyqePWO-Wer9OlM3dgKWZeAw3YW5CzbakzjoORMmG0jD2eldKJLCkj8lQw9gZ0ANXUcH9RVDSLTWv0iF0voNoM1ngyswQXeyhyG_1bz3oAmYluSP3BEMa1rtz-b0x_4Dl5TmltHmA7ldkpTrFw-gaVDpoaPTuLf0cU54UR1UACJZR1CudvywCzK3An4PE4GgOLThYpJYb5GGkwN3c7qlxBsCU6gKNhvDayYpI_zC756g.xpn3molP8Nre4vWl1g_tAMSCl14f4o4QKhbIXdx26Nk"      
  }
}
`}
              </SyntaxHighlighter>
            </p>
            <p>
              <i>example response:</i>
              <SyntaxHighlighter language="json" wrapLines wrapLongLines>
                {`{
  "error": null,
  "data": {
      "access_token": "eyJhbGciOiJFQ0RILUVTIiwiZW5jIjoiQTI1NkNCQy1IUzUxMiIsImVwayI6eyJjcnYiOiJQLTI1NiIsImt0eSI6IkVDIiwieCI6IjE1UlZSa3V1dHRncElxbEs2amxybFh2NnhIcndBNjN0MkVLUzNFUWxlNmsiLCJ5IjoiTm85XzQzMjZFaGg5dG5oNFM3cGljTGp3NmpTal9MM3VtWF92cnBWMjUzdyJ9LCJraWQiOiJwZDFhU0dUd0VhUzV6c0cydWkxenF5eXAzZEY4bVUyTHFiMlo2cjgya04wIiwidHlwIjoiSldFIn0..MhqsAp3KyLEgT1mmPosZxA.LWO4tWCQpAPQ_S9hDYiGr1fHTGGbOiEuiJKpP0Cp2QL4NfDYrqO8MSSXgeqWhoklHynGRr_jqpm9CpL81ppMw59WuRSCF1YONyYcMdROWDhQaBnf31smvsRa21ENLUbchf0EHSlFm0R5HA4Ws2iFJOsQfNPm6zbgiQeo3grIkenwSJpwUlRzBpDzKQ0cBK8d98JCOdILm2m0KZDiDHKhUoAXhUBWOXNF1SplDi3nffN2BpYw1QYUqfWb1nhgZyBifMMXdTPRdmTomSziIwZndKhEsGYSpafiL5oxBQ5VQ9TDa9vC7o-lNr3J2ESVv6Cnn93TO2g3I-IfWO4nwQhd0Ka5r3hrETqG7Jx1o_S4WDNq__RBwOzfk6qTsxl-SyjBSG-0HgqBjb6r3axk1qjhchzm3ZYLZvxW-4thLDfaH9RhVRDWqrcJlFZX_B1sxJURsSzrCwnSV-BtGXj7MA0kzA.vUR5n47Vo23qesOrFXohKFNdcGZpRhlRrzBLyyUhS1Y",
      "grant": "basic",
      "expires_in": 600
  },
  "api": "login",
  "method": "renew",
  "message_id": "72d2fbd1aa3a494e96ece8a05f19c69b"
}`}{" "}
              </SyntaxHighlighter>
            </p>
          </div>
        </>
      ),
    },
    {
      type: "static",
      name: "Signals description",
      text: (
        <>
          <h1>General Signal Structure</h1>
          <p>Signals sent by the system adhere to the following structure:</p>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "<type of notification>",
  "data": <json data>,
  "operationId": "<operation id string>",
  "merchantId": "<merchant id string>"
}`}
          </SyntaxHighlighter>

          <h2>Example State Signal</h2>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "state",
  "data": {
    "operationProgress": {
      "id": "pi_swedbank_adf2db25-51ed-4b4f-911e-fbe98156c45a",
      "state": "new",
      "paymentState": "undefined",
      "currentTask": "resolve merchant by id",
      "errorStr": ""
    }
  }
}`}
          </SyntaxHighlighter>

          <h3>Possible Values</h3>
          <ul>
            <li>
              <strong>state:</strong> new / running / success / failed
            </li>
            <li>
              <strong>paymentState:</strong> undefined / created / redo /
              completed / cancelled
            </li>
            <li>
              <strong>currentTask:</strong> task description
            </li>
            <li>
              <strong>errorStr:</strong> error description (if any)
            </li>
          </ul>

          <h2>Example Update Signal</h2>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "update",
  "data": {
    "piAuthState": {
      "state": "success",
      "token": "",
      "base64Data": ""
    },
    "operationId": "pi_swedbank_adf2db25-51ed-4b4f-911e-fbe98156c45a",
    "merchantId": "4bf60c274b4c4a0daf6af0c6a2a93f6e"
  }
}`}
          </SyntaxHighlighter>

          <h2>Example Selected Signal</h2>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "selected",
  "data": {
    "task": "pi_swedbank_4edfcdfb-208c-4efe-bd6c-2d5b04bf4f4d",
    "accounts": [
      {
        "iban": "SE2080000821498146789980",
        "balance": "1680.76",
        "currency": "sek"
      },
      {
        "iban": "SE1280000821498840835212",
        "balance": "10",
        "currency": "sek"
      }
    ],
    "selected": {
      "iban": "SE2080000821498146789980",
      "balance": "1680.76",
      "currency": "sek"
    }
  }
}`}
          </SyntaxHighlighter>

          <h1>Signing State Signals</h1>
          <p>
            The signing state signals provide notifications related to the
            signing state of various payment transactions. Each signal contains
            information about the current state of a payment signing operation,
            identified by operationId , merchantId , and payId .
          </p>

          <h2>Signal Structure</h2>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "<UUID string>",
      "url": "<string>",
      "status": "<string>",
      "token": "<string>"
    },
    "operationId": "<UUID string>",
    "merchantId": "<UUID string>"
  }
}`}
          </SyntaxHighlighter>

          <h3>Fields</h3>
          <ul>
            <li>
              <strong>notify_type:</strong> Indicates the type of notification,
              in this case, "sign".
            </li>
            <li>
              <strong>data:</strong> Contains details about the signing state.
            </li>
            <li>
              <strong>piSignState:</strong> Contains the state of the payment
              signing process.
            </li>
            <li>
              <strong>payId:</strong> Unique identifier for the payment.
            </li>
            <li>
              <strong>url:</strong> URL related to the payment signing process
              (if applicable).
            </li>
            <li>
              <strong>status:</strong> Current status of the payment signing
              process.
            </li>
            <li>
              <strong>token:</strong> Token related to the payment signing
              process (if applicable).
            </li>
            <li>
              <strong>operationId:</strong> Unique identifier for the operation.
            </li>
            <li>
              <strong>merchantId:</strong> Unique identifier for the merchant.
            </li>
          </ul>

          <h3>Status Values</h3>
          <ul>
            <li>
              <strong>accepted:</strong> The payment has been accepted.
            </li>
            <li>
              <strong>recipMissing:</strong> Recipient information is missing.
            </li>
            <li>
              <strong>authReqd:</strong> Authorization is required.
            </li>
            <li>
              <strong>authDone:</strong> Authorization is done.
            </li>
            <li>
              <strong>completed:</strong> The payment process is completed.
            </li>
            <li>
              <strong>requestAgain:</strong> Another request needs to be made.
            </li>
            <li>
              <strong>failed:</strong> The payment process has failed.
            </li>
          </ul>

          <h3>Example Signals</h3>

          <h4>Accepted Payment</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "edd4612e-f1b2-407d-9401-cd7b3f6ca9d3",
      "url": "",
      "status": "accepted",
      "token": ""
    },
    "operationId": "pi_swedbank_489b7edf-d770-4d9a-bdcf-2e88664fee61",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>

          <h4>Recipient Missing</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "186e1eef-a693-47b0-8dfd-19c687bf9460",
      "url": "",
      "status": "recipMissing",
      "token": ""
    },
    "operationId": "pi_swedbank_70413a6b-abb7-4036-9025-54b2ed23c407",
    "merchantId": "4bf60c274b4c4a0daf6af0c6a2a93f6e"
  }
}`}
          </SyntaxHighlighter>

          <h4>Authorization Required</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "edd4612e-f1b2-407d-9401-cd7b3f6ca9d3",
      "url": "",
      "status": "authReqd",
      "token": "ed33393e-4385-44fb-bca5-df9bec832753"
    },
    "operationId": "pi_swedbank_489b7edf-d770-4d9a-bdcf-2e88664fee61",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>

          <h4>Authorization Done</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "edd4612e-f1b2-407d-9401-cd7b3f6ca9d3",
      "url": "",
      "status": "authDone",
      "token": ""
    },
    "operationId": "pi_swedbank_489b7edf-d770-4d9a-bdcf-2e88664fee61",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>

          <h4>Payment Completed</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "edd4612e-f1b2-407d-9401-cd7b3f6ca9d3",
      "url": "",
      "status": "completed",
      "token": ""
    },
    "operationId": "pi_swedbank_489b7edf-d770-4d9a-bdcf-2e88664fee61",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>

          <h4>Request Again</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "186e1eef-a693-47b0-8dfd-19c687bf9460",
      "url": "",
      "status": "requestAgain",
      "token": ""
    },
    "operationId": "pi_swedbank_70413a6b-abb7-4036-9025-54b2ed23c407",
    "merchantId": "4bf60c274b4c4a0daf6af0c6a2a93f6e"
  }
}`}
          </SyntaxHighlighter>

          <h4>Payment Failed</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "sign",
  "data": {
    "piSignState": {
      "payId": "198234f5-4669-49b7-a408-3e808193a386",
      "url": "",
      "status": "failed",
      "token": ""
    },
    "operationId": "pi_swedbank_c5c9b0be-9499-48a3-8b84-b58c830f63f0",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>

          <h1>Authorization State Signals</h1>
          <p>
            The authorization state signals provide notifications related to the
            authorization state of various payment transactions. Each signal
            contains information about the current state of an authorization
            operation, identified by operationId , merchantId , and state .
          </p>

          <h2>Signal Structure</h2>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "update",
  "data": {
    "piAuthState": {
      "state": "<string>",
      "token": "<string>",
      "base64Data": "<string>"
    },
    "operationId": "<UUID string>",
    "merchantId": "<UUID string>"
  }
}`}
          </SyntaxHighlighter>

          <h3>Fields</h3>
          <ul>
            <li>
              <strong>notify_type:</strong> Indicates the type of notification,
              in this case, "update".
            </li>
            <li>
              <strong>data:</strong> Contains details about the authorization
              state.
            </li>
            <li>
              <strong>piAuthState:</strong> Contains the state of the
              authorization process.
            </li>
            <li>
              <strong>state:</strong> Current state of the authorization
              process. Possible values are success , failed , timedOut , pending
              , rejected .
            </li>
            <li>
              <strong>token:</strong> A string representing the bank ID ast or
              QR code
            </li>
            <li>
              <strong>base64Data:</strong> A base64 encoded string representing
              a QR code (swedbank only).
            </li>
            <li>
              <strong>operationId:</strong> Unique identifier for the operation.
            </li>
            <li>
              <strong>merchantId:</strong> Unique identifier for the merchant.
            </li>
          </ul>

          <h3>Example Signals</h3>

          <h4>Authorization Success</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "update",
  "data": {
    "piAuthState": {
      "state": "success",
      "token": "",
      "base64Data": ""
    },
    "operationId": "pi_swedbank_f076bca8-47c9-4f54-9d5c-dd31985ed95a",
    "merchantId": "4bf60c274b4c4a0daf6af0c6a2a93f6e"
  }
}`}
          </SyntaxHighlighter>

          <h4>Authorization with QR Code</h4>
          <SyntaxHighlighter language="json" wrapLines wrapLongLines>
            {`{
  "notify_type": "update",
  "data": {
    "piAuthState": {
      "state": "pending",
      "token": "",
      "base64Data": "iVBORw0KGgoAAAANSUhEUgAA..."
    },
    "operationId": "pi_swedbank_1234abcd-5678-efgh-ijkl-9876543210mn",
    "merchantId": "a3f711499a524e29a78da5b3b957e702"
  }
}`}
          </SyntaxHighlighter>
        </>
      ),
    },
    {
      type: "static",
      name: "loading",
      text: (
        <>
          <center>
            <Spinner />
            <br />
            Loading page
          </center>
        </>
      ),
    },
  ];

  useEffect(() => {
    let page = undefined;
    if (params.type === "static") {
      page = staticPages.find((value) => {
        return (
          params.type === value.type &&
          params.name?.replaceAll("_", " ") === value.name
        );
      });
    }
    if (params.type === "query") {
      page = queryCommands.find((value: any) => {
        return (
          params.type === value.type &&
          params.name?.replaceAll("_", " ") === value.name
        );
      });
    }
    if (params.type === "mutation") {
      page = mutationCommands.find((value: any) => {
        return (
          params.type === value.type &&
          params.name?.replaceAll("_", " ") === value.name
        );
      });
    }
    if (page === undefined || page === null) {
      page = staticPages.find((value) => {
        return value.type === "static" && value.name === "loading";
      });
    }

    onSelect({
      type: page?.type,
      detail: page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, queryCommands, mutationCommands]);

  useEffect(() => {
    if (apiDocumentSchema) {
      setQueryCommands(
        apiDocumentSchema.filter((item: any) => item.type === "query")
      );
      setMutationCommands(
        apiDocumentSchema.filter((item: any) => item.type === "mutation")
      );
    }
  }, [apiDocumentSchema]);

  const renderCommandList = (
    commands: any,
    type: "query" | "mutation" | "static"
  ) => {
    const items: ReactNode[] = [];
    if (commands) {
      for (const [key, value] of Object.entries(commands as Object)) {
        items.push(
          <ListGroup.Item
            key={key}
            as="li"
            action
            active={value.name === selectedItemDetail?.name}
            onClick={() => {
              onSelect({
                type,
                detail: value,
              });
              const url = value.name.replaceAll(" ", "_");
              navigate(`${Paths.docs}/${type}/${url}`);
            }}
            className={clsx(
              styles.item,
              value.name === selectedItemDetail?.name ? styles.itemActive : ""
            )}
          >
            {value.name}
          </ListGroup.Item>
        );
      }
    }
    return items;
  };
  /*
   */
  return (
    <Container className={styles.container}>
      <h3>Documentation</h3>
      <ListGroup as="ul">
        {renderCommandList(
          staticPages.filter((value) => value.name !== "loading"),
          "static"
        )}
      </ListGroup>
      <br />
      <h3>Queries</h3>
      {queryCommands.length > 0 ? (
        <ListGroup as="ul">
          {renderCommandList(queryCommands, "query")}
        </ListGroup>
      ) : (
        <center>
          <Spinner />
          <br />
          Loading data....
        </center>
      )}
      <h3>Mutations</h3>
      {queryCommands.length > 0 ? (
        <ListGroup as="ul">
          {renderCommandList(mutationCommands, "mutation")}
        </ListGroup>
      ) : (
        <center>
          <Spinner />
          <br />
          Loading data....
        </center>
      )}
    </Container>
  );
};
