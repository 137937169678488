import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import { RouterProvider } from "react-router-dom";
import { router } from "./utils/router";
import AppProvider from "providers/AppProvider";

import * as serviceWorker from "./serviceWorker";

declare global {
  interface Window {
    App: any;
  }

  var App: any;
}
window.App = window.App || ({} as { channel: BroadcastChannel });

App.logData = (data: object) => {
  console.log(`LOGGER: ${JSON.stringify(data)}`);
};

window.addEventListener("error", function (error) {
  console.error(error);
  App.logData(error.message);
  error.preventDefault();
});

App.channel = new BroadcastChannel("persist_data");

App.sendMessage = (message: string) => {
  return new Promise(function (resolve, reject) {
    var messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };
    navigator.serviceWorker?.controller?.postMessage(message, [
      messageChannel.port2,
    ]);
  });
};
serviceWorker.register();


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AppProvider>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
  </AppProvider>
);
