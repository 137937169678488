import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useApp } from "../../../providers/AppProvider";
import style from "./style.module.scss";

const LoginAuthEIdComponent = (props: {
  onLoginAuthEIdSuccess: (token: string) => void;
}): React.JSX.Element => {
  const { login } = useApp();
  const [requestId, setRequestId] = useState<string>("");

  useEffect(
    function setupListener() {
      function handleListener(event: MessageEvent<any>) {
        if (
          event.data.system === "autheid" &&
          event.data.method === "connect" &&
          event.data.data === true
        ) {
          App.sendMessage({ service: "autheid", data: { method: "login" } });
        }

        if (event.data.system === "autheid" && event.data.method === "qrcode") {
          setRequestId(event.data.data);
        }
        if (event.data.system === "autheid" && event.data.method === "login") {
          login(
            event.data.data.token,
            event.data.data.expire,
            event.data.data.isAdmin
          );
        }
      }
      App.channel.addEventListener("message", handleListener);

      return function cleanupListener() {
        App.channel.removeEventListener("message", handleListener);
      };
    },
    [login]
  );
  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (requestId.length === 0) {
        App.sendMessage({ service: "autheid", data: { method: "login" } });
      }
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [requestId.length]);

  return (
    <div className={style.container}>
      <p className={style.header}>Login with Auth eID App </p>
      <p className={style.subheader}>
        Don't have the Auth eID App? <br />
        <a target="_blank" href="https://autheid.com/" rel="noreferrer">
          Get started here.
        </a>
      </p>
      <center>
        {requestId ? (
          <QRCode
            value={`autheid://autheid.com/app/requests/?request_id=${requestId}`}
          />
        ) : (
          <>Connecting to auth server</>
        )}
      </center>
      <div>
        <div className={style.numbers}>
          <ol>
            <li>Open the Auth eID App on your mobile phone.</li>
            <li>Tap the QR symbol on the Auth eID App.</li>
            <li>Point the camera at the QR code in this field.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default LoginAuthEIdComponent;
