import React, { useEffect, useMemo, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import style from "./style.module.scss";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import LandingPage from "components/Landing";

interface QueryDetailProps {
  query?: any;
  text?: string;
}

const QueryDetailContainer = ({
  query,
}: QueryDetailProps): React.JSX.Element => {
  const [activeTab, setActiveTab] = useState("");

  const responseObject = useMemo(() => {
    const response_data = query?.detail.response_data;
    if (!response_data) {
      return undefined;
    }
    const obj = response_data?.replace(/(\w+): (\w+)/gm, "$1: $2\n");
    return `${query?.detail.response} {\n ${obj} \n}`;
  }, [query?.detail.response, query?.detail.response_data]);
  return (
    <>
      <section style={{ minHeight: 100 }}>
        <h3>
          <span className={style.queryType}>{`${
            query?.type ?? "Query"
          }: `}</span>
          {query?.detail.name}
        </h3>

        <div
          className={style.description}
          dangerouslySetInnerHTML={{ __html: query?.detail.description }}
        ></div>
      </section>

      <section
        style={{
          paddingTop: 10,
          borderTop: "1px solid #a0a0a0",
        }}
      >
        <h4>
          <span className={style.title}>Arguments: </span>
        </h4>
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <ul>
            {query?.detail.arguments?.split(",").map(
              (item: any) =>
                item && (
                  <li>
                    <p>
                      <span>{`${item}`}</span>
                    </p>
                  </li>
                )
            )}
          </ul>
        </div>
      </section>
      <section
        style={{
          paddingTop: 10,
          boxSizing: "border-box",
          borderTop: "1px solid #a0a0a0",
        }}
      >
        <h4>
          <span className={style.title}>Response Type: </span>
          <span>{query?.detail.response}</span>
        </h4>
        {responseObject && (
          <SyntaxHighlighter language="graphql">
            {responseObject}
          </SyntaxHighlighter>
        )}
      </section>
      <section>
        {query?.detail?.codes.length > 0 && (
          <h4>
            <span className={style.title}>Code example: </span>
          </h4>
        )}
        <Tabs id="uncontrolled-tab-example" className="mb-3">
          {query?.detail?.codes?.map((item: any) => {
            if (activeTab === "") {
              setActiveTab(item.language_name);
            }
            return (
              <Tab eventKey={item.language_name} title={item.language_name}>
                <SyntaxHighlighter language={item.language_name}>
                  {item.code}
                </SyntaxHighlighter>
              </Tab>
            );
          })}
        </Tabs>
      </section>
    </>
  );
};

export const QueryDetail = (props: QueryDetailProps): React.JSX.Element => {
  return (
    <Container className={style.container}>
      {props.query?.type === "query" || props.query?.type === "mutation" ? (
        <QueryDetailContainer query={props.query} />
      ) : props.query?.detail?.text ? (
        <>{props.query?.detail?.text}</>
      ) : (
        <LandingPage />
      )}
    </Container>
  );
};
