import { Navigate, createBrowserRouter } from "react-router-dom";
import React from "react";
import Login from "shared-components/components/Modals/Login";
import GraphQL from "pages/GraphQL";
import Layout from "components/Layout";

export const Paths = {
  landing: "/",
  docs: "/docs",
  login: "/login",
};
/*
          header={"doc"}
          fluid={true}
          chess1Disabled={true}
          chess2Disabled={true}

*/
export const router = createBrowserRouter([
  {
    path: "/",
    Component: () => {
      return <Layout />;
    },
    children: [
      {
        path: Paths.landing,
        element: <Navigate to={`${Paths.docs}/static/Home`} replace />,
        /* <LandingPage />, */
      },
      {
        path: Paths.docs,
        element: <Navigate to={`${Paths.docs}/static/Home`} replace />,
      },
      {
        path: `${Paths.docs}/:type/:name`,
        element: <GraphQL />,
      },
      {
        path: Paths.login,
        element: <Login redirectUrl={Paths.docs} />,
      },
    ],
  },
]);
