import React, { useCallback, useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Container, Form, Row } from "react-bootstrap";
import clsx from "clsx";
import { QueryList } from "components/QueryList";
import { QueryDetail } from "components/QueryDetail";
import CopyButton from "shared-components/components/CopyButton";
import { query as gQuery, mutation as gMutation } from "gql-query-builder";
import { withAuth } from "components/hoc/withAuth";
import api from "utils/api";

const GraphqlQueryEditor = (props: any): React.JSX.Element => {
  const [queryList, setQueryList] = useState([]);
  const [_busy, setBusy] = useState(false);

  const [query, setQuery] = useState<string>("");
  const [variables, setVariables] = useState<string>("");
  const [_output, setOutput] = useState<string>("");

  const [selectedQuery, setSelectedQuery] = useState<any>();

  const handleSelectQuery = useCallback((query: any) => {
    setSelectedQuery(query);
    const type = query?.type;
    if (type !== "query" && type !== "mutation") {
      return;
    }
    const fields = query?.detail.response_data
      ?.replace(/(\w+): (\w+)/gm, "$1,")
      .split(",");
    let mVariables = {};
    if (query.detail?.arguments !== "") {
      query.detail?.arguments.split(",").forEach((item: string) => {
        const obj = item.split(/(\w+): (\w+)/g);
        const key = obj[1];
        mVariables = { ...mVariables, [key]: "" };
      });
    }
    const payload = {
      operation: query.detail?.name,
      fields: fields,
      variables: mVariables,
    };
    const generateQuery =
      type === "query"
        ? gQuery(payload)
        : type === "mutation"
        ? gMutation(payload)
        : undefined;
    setQuery(generateQuery?.query ?? "");
    setVariables(JSON.stringify(generateQuery?.variables) || "");
  }, []);

  /* FETCH QUERIES */
  useEffect(() => {
    setBusy(true);
    api
      .getIntrospection()
      .then((response) => {
        setQueryList(response);
        setBusy(false);
      })
      .catch((e) => {
        setBusy(false);
      });
  }, []);
  /* FETCH QUERIES */

  const handleQuery = useCallback(() => {
    if (query) {
      let varsToSend = undefined;
      try {
        varsToSend = JSON.parse(variables);
      } catch (e) {
        const error: Error = e as Error;
        setOutput(JSON.stringify({ error: error.message }));
        return;
      }
      App.sendMessage({
        service: "graphql",
        data: {
          query: query,
          params: { variables: varsToSend },
        },
      });
    }
  }, [query, variables]);

  useEffect(function setupListener() {
    function handleListener(event: MessageEvent<any>) {
      if (event.data.system === "graphql" && event.data.method === "response") {
        const json = event.data.data;
        if (json && json.data?.__schema) {
        } else if (!json.error) {
          setOutput(json);
        }
      }
    }
    App.channel.addEventListener("message", handleListener);

    return function cleanupListener() {
      App.channel.removeEventListener("message", handleListener);
    };
  }, []);

  return (
    <Form className={clsx(styles.editor, "d-flex column relative")}>
      <Container className="d-flex flex-column position-relative mw-100 px-4 ">
        {/* temporary disable bar */
        /*<Container className="d-flex bg-body-tertiary justify-content-between align-items-center m-0 mw-100">
          <SelectVersion
            value={selectedVersion?.value}
            items={mockVersions}
            onSelect={setSelectedVersion}
          />
          <DocumentsComponent value={graphqlDocuments} />
        </Container>
        */}
        <div className={styles.queriesContent}>
          <QueryList
            selectedItem={selectedQuery}
            onSelect={handleSelectQuery}
            apiDocumentSchema={queryList}
          />
          <QueryDetail query={selectedQuery} />
          <Row className={styles.queryImplement}>
            <div className={styles.queryContainer}>
              <Form.Group
                className={clsx(
                  styles.inputQuery,
                  "mb-3 flex-1 position-relative"
                )}
              >
                <Form.Label>Query</Form.Label>
                <Form.Control
                  as="textarea"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className={clsx(styles.textareaQuery)}
                />
                <CopyButton value={query} className={styles.copyButton} />
              </Form.Group>
              <Form.Group className="mt-3 flex-1 position-relative">
                <Form.Label>Variables</Form.Label>
                <Form.Control
                  as="textarea"
                  value={variables}
                  rows={3}
                  onChange={(e) => setVariables(e.target.value)}
                  className={styles.textareaVariables}
                />
                <CopyButton value={variables} className={styles.copyButton} />
              </Form.Group>
            </div>
            {/*
            <Button
              className={clsx(styles.runButton, "my-2", "ml-2")}
              onClick={() => onActionWithAuth(handleQuery)}
            >
              Run
            </Button>

            <div className={styles.responseContainer}>
              <Form.Group
                className="w-100 h-100 d-flex flex-column"
                controlId="ControlResult"
              >
                <Form.Label>Response</Form.Label>
                <ResponseView data={output} />
              </Form.Group>
            </div>
            */}
          </Row>
        </div>
      </Container>
    </Form>
  );
};

export default withAuth(GraphqlQueryEditor);
