import React from "react";

const LandingPage = () => {
  return (
    <>
      <div className="infinipi-doc">
        <br />
        <h1>Welcome to the Infinipi API Access Documentation!</h1>
        <p>
          Here, merchants will discover seamless integration possibilities for
          their payment sites with our cutting-edge payment gateway. This
          comprehensive guide empowers merchants to effortlessly integrate their
          systems with Infinipi's API, enabling streamlined access to
          transactional data, particularly focusing on pending transactions.
        </p>

        <h2>1. Getting Started</h2>
        <p>
          Step-by-step instructions on how to obtain API credentials and
          authenticate access to the Infinipi platform.
        </p>

        <h2>2. Integration Guidelines</h2>
        <p>
          Detailed guidance on integrating the Infinipi API seamlessly into
          merchant payment sites, ensuring smooth communication and data
          exchange.
        </p>

        <h2>3. Transaction Retrieval</h2>
        <p>
          Specific endpoints and methods to retrieve pending transaction data
          efficiently.
        </p>

        <h2>4. Error Handling and Troubleshooting</h2>
        <p>
          Strategies for addressing common issues and handling errors during
          integration and data retrieval processes.
        </p>

        <p>
          By integrating with Infinipi's API, merchants can optimize their
          payment workflows, gain valuable insights into pending transactions,
          and offer customers a seamless payment experience.
        </p>

        <p>
          Empower your payment site with Infinipi's powerful API today and
          elevate your transaction management to new heights!
        </p>
        <p>
          To access the Infinipi API features, please log in using the login
          button on top.
        </p>
      </div>
    </>
  );
};

export default LandingPage;
