import React, { useCallback, useState } from "react";
import mstyle from "./style.module.scss";
import clsx from "clsx";
import { CopyIcon } from "assets/icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

interface props {
  value?: string;
  className?: string;
  style?: React.CSSProperties;
}
const CopyButton = ({ className, style, value }: props): React.JSX.Element => {
  const [isShow, setShow] = useState(false);
  const handleCopy = useCallback(async () => {
    setShow(true);
    await navigator.clipboard.writeText(value || "").then(() => {
      setTimeout(() => setShow(false), 1000);
    });
  }, [value]);
  return (
    <OverlayTrigger
      placement={"top"}
      show={isShow}
      overlay={
        <Popover
          id={`popover-contained`}
          className={mstyle.popover}
          arrowProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Popover.Body className="px-2 py-1 text-white">
            <span>copied</span>
          </Popover.Body>
        </Popover>
      }
    >
      <img
        alt={"copy"}
        src={CopyIcon}
        className={clsx(mstyle.icon, className)}
        style={style}
        onClick={handleCopy}
      />
    </OverlayTrigger>
  );
};

export default CopyButton;
