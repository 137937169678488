import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginAuthEIdComponent from "components/Modals/Login";
import { Paths } from "utils/router";
import { useApp } from "providers/AppProvider";

const LoginAuthEIdModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  onLoginAuthEIdSuccess: (token: string) => void;
}): React.JSX.Element => {
  const { isOpen, onClose, onLoginAuthEIdSuccess } = props;
  const [show, setShow] = useState(isOpen);

  const { userData } = useApp();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (userData.access_token) {
      onLoginAuthEIdSuccess(userData.access_token);
    }
  }, [userData.access_token, show, navigate, onLoginAuthEIdSuccess]);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <LoginAuthEIdComponent
          onLoginAuthEIdSuccess={(accessToken) => {
            if (accessToken) {
              navigate(Paths.landing);
            }
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default LoginAuthEIdModal;
