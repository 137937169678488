// src/serviceWorker.ts

import { autheidWsHost, graphqlWsHost } from "./shared-components/configuration";

export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      const swUrl = `/service-worker.js?autheid_url=${autheidWsHost}&graphql_url=${graphqlWsHost}`;

      navigator.serviceWorker
        .register(swUrl, {
          type: "module",
          scope: "/",
          updateViaCache: "none", 
        })
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
          App.sendMessage();

        })
        .catch((error) => {
          console.error("Error during service worker registration:", error);
        });
      navigator.serviceWorker.addEventListener("updatefound", () => {
        console.log("Service Worker update detected!");
      });
      navigator.serviceWorker.addEventListener("controllerchange", function () {
        console.log("CONTROLLERCHANGE");
      });
    });
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error("Error during service worker unregistration:", error);
      });
  }
}
