import { apiHost } from "../configuration";

enum RequestType {
  GET = "GET",
  POST = "POST",
}

export class Api {
  headers = {};
  token = "";
  constructor() {
    this.setHeader("Content-Type", "application/json");
  }

  setHeader(key: string, value: string): void {
    Object.assign(this.headers, { [key]: value });
  }

  async sendRequest(
    endpoint: string,
    type: RequestType,
    data?: any
  ): Promise<any> {
    return await fetch(`${apiHost}/${endpoint}`, {
      method: type.toString(),
      headers: this.headers,
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        //      const isJson = response.headers.get('content-type')?.includes('application/json')
        const data = await response.json();
        if (!response.ok) {
          const error = data ?? response.status;
          return await Promise.reject(error);
        }
        return data;
      })
      .catch((error) => {
        console.error("Error message:", error);
      });
  }

  async getIntrospection() {
    const response = await this.sendRequest("introspection", RequestType.GET);
    if (!response?.error) {
      return response;
    }
    return []
  }
}

const api = new Api();
export default api;
