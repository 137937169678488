import React from "react";
import GraphqlQueryEditor from "components/graghql-editor";

const GraphQL = () => {
  return (
    <>
      <GraphqlQueryEditor />
    </>
  );
};

export default GraphQL;
