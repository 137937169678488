import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useApp } from "../../../../providers/AppProvider";
import style from "./style.module.scss";
import { AdminUrl, MerchantPrefix } from "../../../configuration";
interface LoginProps {
  redirectUrl?: string;
}
const Login = ({ redirectUrl }: LoginProps): React.JSX.Element => {
  const [show, setShow] = useState(false);

  const { userData, login } = useApp();
  const [requestId, setRequestId] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(
    function setupListener() {
      function handleListener(event: MessageEvent<any>) {
        if (
          event.data.system === "autheid" &&
          event.data.method === "connect" &&
          event.data.data === true
        ) {
          App.sendMessage({ service: "autheid", data: { method: "login" } });
        }

        if (event.data.system === "autheid" && event.data.method === "qrcode") {
          setRequestId(event.data.data);
        }
        if (event.data.system === "autheid" && event.data.method === "login") {
          login(
            event.data.data.token,
            event.data.data.expire,
            event.data.data.isAdmin
          );
        }
      }
      App.channel.addEventListener("message", handleListener);

      return function cleanupListener() {
        App.channel.removeEventListener("message", handleListener);
      };
    },
    [login]
  );

  useEffect(() => {
    setShow(!userData.loggedIn);
    if (userData.loggedIn && location.pathname === "/login") {
      navigate((redirectUrl) ? redirectUrl : `${MerchantPrefix}/${AdminUrl.DASHBOARD}`);
    }
  }, [userData.loggedIn, show, navigate, location.pathname, redirectUrl]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (requestId?.length === 0) {
        App.sendMessage({ service: "autheid", data: { method: "login" } });
      }
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [requestId?.length]);

  useEffect(() => {
    if (show) {
      App.sendMessage({ service: "autheid", data: { method: "login" } });
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className={style.header}>Login with Auth eID App </p>
          <p className={style.subheader}>
            Don't have the Auth eID App? <br />
            <a target="_blank" href="https://autheid.com/" rel="noreferrer">
              Get started here.
            </a>
          </p>
          <center>
            {requestId && (
              <QRCode
                value={`autheid://autheid.com/app/requests/?request_id=${requestId}`}
              />
            )}
          </center>
          <div>
            <div className={style.numbers}>
              <ol>
                <li>Open the Auth eID App on your mobile phone.</li>
                <li>Tap the QR symbol on the Auth eID App.</li>
                <li>Point the camera at the QR code in this field.</li>
              </ol>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
