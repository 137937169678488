import { useApp } from "providers/AppProvider";
import React, { ComponentType, useCallback, useState } from "react";
import LoginAuthEIdModal from "../Modals/Login/loginModal";

type AuthProps = {
  onActionWithAuth: (callback: any) => void;
};

export function withAuth<T>(Component: ComponentType<T>) {
  return (hocProps: T & AuthProps) => {
    const { userData } = useApp();
    const [isShowModal, setShowModal] = useState(false);

    const onActionWithAuth = useCallback(
      (callBack: () => any) => {
        if (!userData.access_token) {
          setShowModal(true);
          return null;
        } else {
          return callBack();
        }
      },
      [userData.access_token]
    );

    const onLoginAuthEIdSuccess = useCallback(() => {
      setShowModal(false);
    }, [setShowModal]);
    return (
      <>
        <LoginAuthEIdModal
          isOpen={isShowModal}
          onClose={() => {
            setShowModal(false);
          }}
          onLoginAuthEIdSuccess={onLoginAuthEIdSuccess}
        />
        <Component {...hocProps} onActionWithAuth={onActionWithAuth} />
      </>
    );
  };
}
