import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BaseProps } from "../../shared-components/utils/base_interface";
import { UserData } from "../../types/userData";
import { v4 as uuidv4 } from "uuid";
import { App } from "../../types/appType";
import {
  AdminPrefix,
  AdminUrl,
  PageUrl,
  environment,
} from "../../shared-components/configuration";
import React from "react";
import { Paths } from "utils/router";

const initialValues: App = {
  sidebarExpanded: false,
  theme: "",
  userData: {
    email: "",
    iban: "",
    counterpartyId: "",
    access_token: "",
    loggedIn: false,
    isAdmin: false,
  },

  setSidebarExpanded: function (value: boolean): void {
    throw new Error("Function not implemented.");
  },
  setTheme: function (value: string): void {
    throw new Error("Function not implemented.");
  },
  setUserData: function (value: UserData): void {
    throw new Error("Function not implemented.");
  },
  logout: function (): void {
    throw new Error("Function not implemented.");
  },
  login: function (token: string, expires_in: number, isAdmin: boolean): void {
    throw new Error("Function not implemented.");
  },
  setEmail: function (value: string): void {
    throw new Error("Function not implemented.");
  },
  setIban: function (value: string): void {
    throw new Error("Function not implemented.");
  },
  setCounterpartyID: function (value: string): void {
    throw new Error("Function not implemented.");
  },
};
const AppContext = createContext<App>(initialValues);

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error("Error not found");
  return context;
};

const initUserData = {
  email: "",
  iban: "",
  counterpartyId: "",
  access_token: "",
  loggedIn: false,
  isAdmin: localStorage.getItem("isAdmin") === "true",
};

const AppProvider = ({ children }: BaseProps) => {
  //  const { token } = useLogin();
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);
  const [theme, setTheme] = useState<string>("light");
  const [userData, setUserData] = useState<UserData>({
    email: localStorage.getItem("email") ?? "",
    iban: localStorage.getItem("iban") ?? "",
    counterpartyId: localStorage.getItem("counterpartyId") ?? "",
    access_token: "",
    loggedIn: false,
    isAdmin: localStorage.getItem("isAdmin") === "true",
  });

  useEffect(() => {
    if (
      localStorage.getItem("UUID") === null ||
      localStorage.getItem("UUID") === ""
    ) {
      localStorage.setItem("UUID", uuidv4());
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("token", userData.access_token);
    localStorage.setItem("isAdmin", userData.isAdmin?.toString());
    localStorage.setItem("email", userData.email);
    localStorage.setItem("iban", userData.iban);
    localStorage.setItem("counterpartyId", userData.counterpartyId);
  }, [userData]);

  const logout = useCallback(() => {
    localStorage.removeItem("email");
    localStorage.removeItem("iban");
    localStorage.removeItem("counterpartyId");
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.App.sendMessage({ service: "logout" });
    setUserData(initUserData);

    if (window.location.pathname !== Paths.docs) {
      window.location.href =
        environment === "adminprod"
          ? `${AdminPrefix}/${AdminUrl.DASHBOARD}`
          : PageUrl.LANDING;
    }
  }, []);

  const login = useCallback(
    (token: string, expires_in: number, isAdmin: boolean) => {
      localStorage.setItem("token", token);
      setUserData({
        ...userData,
        loggedIn: true,
        access_token: token,
        isAdmin: isAdmin,
      });
    },
    [userData]
  );

  const setIban = useCallback((value: string) => {
    setUserData((prev) => ({ ...prev, iban: value }));
  }, []);

  const setEmail = useCallback((value: string) => {
    setUserData((prev) => ({ ...prev, email: value }));
  }, []);

  function setCounterpartyID(value: string) {
    setUserData((prev) => ({ ...prev, counterpartyId: value }));
  }

  useEffect(() => {
    if (userData.loggedIn === false) {
      setIban("");
    }
  }, [setIban, userData.loggedIn]);

  useEffect(
    function setupListener() {
      function handleListener(event: MessageEvent<any>) {
        if (
          (event.data.system === "logout" || event.data.data?.error === true) &&
          userData.loggedIn
        ) {
          setTimeout(() => {
            logout();
          }, 3000);
        }
        if (event.data.system === "getData") {
          if (
            (event.data.data.expire > 0 && event.data.data.token.length > 10) ||
            event.data.data.isAdmin
          ) {
            login(
              event.data.data.token,
              event.data.data.expire,
              event.data.data.isAdmin
            );
          }
        }
      }
      window.App.channel.addEventListener("message", handleListener);

      return function cleanupListener() {
        window.App.channel.removeEventListener("message", handleListener);
      };
    },
    [login, logout, userData]
  );

  useEffect(() => {
    window.App.sendMessage({ service: "getData" });
  }, []);
  return (
    <>
      <AppContext.Provider
        value={{
          sidebarExpanded,
          theme,
          userData,
          setSidebarExpanded,
          setTheme,
          setUserData,
          logout,
          login,
          setCounterpartyID,
          setIban,
          setEmail,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};

export default AppProvider;
